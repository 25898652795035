// Home4.js

import React, { useEffect, useRef } from 'react';
import './Home4.css';
import xrImage from './xr.png'; // Import the image

const Home4 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log('Intersection Observer Entry:', entry); // Debugging log
        if (entry.isIntersecting) {
          console.log('Element is visible, adding class'); // Debugging log
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0, rootMargin: '0px 0px 80% 0px' }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home4-container">
      <div className="home4-content" ref={contentRef}>
        <h1 className="home4-text">How We Do It</h1>
        <div className="home4-subtext">
          By combining educational psychology with Extended Reality & Artificial Intelligence, our applications empower learners with cutting edge ways to learn modern technologies and programming concepts.
        </div>
        <img src={xrImage} alt="XR Technologies" className="home4-image" /> {/* Use the imported image */}
      </div>
    </div>
  );
};

export default Home4;
