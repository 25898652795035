// Home8.js

import React from 'react';
import './Home8a.css';

const Home8a = () => {
  return (
    <div className="home8a-container">
      <div className="home8a-text">
        Accessibility
      </div>
    </div>
  );
};

export default Home8a;
