// Home6.js

import React, { useEffect, useRef } from 'react';
import './Home6.css';
import visionPro from './mrg3.png'; 


const Home6 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home6-container">
      <div className="home6-content" ref={contentRef}>
        <div className="home6-image-container">
          <img src={visionPro} alt="Vision Pro" className="home6-image" />
        </div>
        
        <div className="home6-text-container">
          <h1 className="home6-text">
            <span className="home6-text-part left">Mixed</span>
            <span className="home6-text-part right">Reality</span>
          </h1>
          <div className="home6-subtext">
            <p>We recognize that being introduced to new technologies can be challenging and sometimes it takes a while for a learner to fully grasp these complex concepts.</p>
            <p>Multiple studies have shown that a learner's ability to visually see and dynamically interact with physical representations of abstract concepts dramatically increases the speed and depth of their understanding.</p>
            <p>We've designed the Vision Pro version of our app to create highly immersive and interactive learning experiences.</p>
            <p>By implementing proven ed-Psych methods through Augmented & Virtual Reality, our users get to actively engage with our learning content and create their own personal understanding of abstract tech concepts.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home6;
