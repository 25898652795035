// FooterComponent.js

import React from 'react';
import './FooterComponent.css'; // Import the CSS file
import bridgetLogo from './bridgetlogo.png'; // Replace with your actual image path

const FooterComponent = () => {
  return (
    <div className="footer-container">
      <div className="footer-left">
        <img src={bridgetLogo} alt="Bridget Logo" className="footer-logo" />
      </div>
      <div className="footer-middle">
        <p className="footer-text">Sign up with your email address to receive news and updates.</p>
        <div className="footer-form">
          <input type="email" placeholder="Email Address" className="footer-input" />
          <button className="footer-button">Sign Up</button>
        </div>
      </div>
      <div className="footer-right">
        <a href="#about" className="footer-link">About</a>
        <a href="#founder" className="footer-link">About the Founder</a>
        <a href="#contact" className="footer-link">Contact</a>
      </div>
    </div>
  );
};

export default FooterComponent;
