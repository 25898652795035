// Home2.js

import React, { useEffect, useRef } from 'react';
import './Home2.css';

const Home2 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home2-container">
      <div className="home2-content" ref={contentRef}>
        <h1 className="home2-text">Who We Are</h1>
        <div className="home2-subtext">
          <span className="highlight-b">B</span>ridget Technologies is an innovative, nonprofit research organization based in Detroit, dedicated to making technology education more accessible and easier to understand.
        </div>
      </div>
    </div>
  );
};

export default Home2;
