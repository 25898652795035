import React, { useState } from 'react';
import { SimpleGrid, Card, CardHeader, CardBody, CardFooter, Heading } from '@chakra-ui/react';
import './ProductGrid.css';
import mrg3 from './mrg3.png'; 
import phone from './phone.png'; 
import mrman from './mrman.png'; 

const ProductGrid = () => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const products = [
    { title: 'i2', description: 'This is the i2 product, which focuses on XR Technologies.', image: mrg3 },
    { title: 'i2 Mobile', description: 'This is the i2 Mobile product, optimized for mobile XR experiences.', image: phone },
    { title: 'i2 Labs', description: 'This is the i2 Labs product, designed for immersive learning.', image: mrman },
    { title: 'eye2', description: 'This is the eye2 product, offering advanced visual technologies.', image: mrg3 },
  ];

  const openModal = (product) => {
    setModalContent(product.description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className="product-grid-wrapper">
      <SimpleGrid spacing={4} templateColumns="repeat(4, 1fr)">
        {products.map((product, index) => (
          <Card className="card-container" key={index}>
            <CardHeader>
              <Heading size='md' className="card-heading">{product.title}</Heading>
            </CardHeader>
            <CardBody>
              <img src={product.image} alt={product.title} />
            </CardBody>
            <CardFooter>
              <button className="custom-button" onClick={() => openModal(product)}>More</button>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>X</button>
            <p>{modalContent}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductGrid;
