// Home7.js

import React, { useEffect, useRef } from 'react';
import './Home7.css';
import robo from './robo.png'; // Import the image

const Home7 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home7-container">
      <div className="home7-content" ref={contentRef}>
        <h1 className="home7-text">
          <span className="text-part7 left">Artificial</span>
          <span className="text-part7 right">Intelligence</span>
        </h1>
        <div className="home7-subtext-image-container">
          <div className="home7-subtext">
            <p>Conventional teachers are always limited in their availability, and technology tutors are expensive.</p>
            <p>This makes it impossible for learners to be met where they are in their learning journey and personally guided towards understanding.</p>
            <p>We know that a learner's success is heavily influenced by whether or not they have access to personalized guidance when they need it.</p>
            <p>Modern advancements in generative AI have allowed us to solve this problem by embedding custom AI models in our applications for every subject we teach.</p>
            <p>We train each model on a specific domain and combine it with over 20K pages of the latest books and research papers in educational psychology.</p>
            <p>This gives our learners access to real-time AI helpers that are highly knowledgeable and available 24/7, whenever a learner needs them!</p>
          </div>
          <img src={robo} alt="AI Technologies" className="home7-image" />
        </div>
      </div>
    </div>
  );
};

export default Home7;
