import React from 'react';
import './translate.css';

const TranslateBox = () => {
  return (
    <div className="translate-container">
      <h1 className="translate-title">Translate</h1>
      <textarea
        className="translate-textarea"
        placeholder="Enter text here..."
      />
      <div className="translate-controls">
        <select className="translate-dropdown">
          <option value="swedish">Swedish</option>
          <option value="portuguese">Portuguese</option>
          <option value="italian">Italian</option>
          <option value="urdu">Urdu</option>
          <option value="serbian">Serbian</option>
        </select>
        <button className="translate-button">
          Submit
        </button>
      </div>
    </div>
  );
};

export default TranslateBox;
