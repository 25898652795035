// Home5.js

import React, { useEffect, useRef } from 'react';
import './Home5.css';
import blueBrain from './bluebrain.png'; // Import the image

const Home5 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home5-container">
      <div className="home5-content" ref={contentRef}>
        <h1 className="home5-text">
          <span className="text-part left">Educational</span>
          <span className="text-part right">Psychology</span>
        </h1>
        <div className="home5-subtext-image-container">
          <div className="home5-subtext">
            <p>We design all features of our applications with a foundation in proven concepts from the field of educational psychology.</p>
            <p>One of the primary concepts used is the Zone of Proximal Development (ZPD).</p>
            <p>With the ZPD approach, our applications dynamically allocate content and real-time AI assistance to meet learners exactly where they are in their learning journey.</p>
            <p>This method improved students' subject mastery by 74% in a University of Pennsylvania study.</p>
          </div>
          <img src={blueBrain} alt="Educational Psychology" className="home5-image" />
        </div>
      </div>
    </div>
  );
};

export default Home5;
