// Home3.js

import React, { useEffect, useRef } from 'react';
import './Home3.css';

const Home3 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0 } // Increase the threshold to avoid premature animation
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home3-container">
      <div className="home3-content" ref={contentRef}>
        <h1 className="home3-text">What We Do</h1>
        <div className="home3-subtext">
          <span className="highlight-c">B</span>ridget bridges the gap between a learner's desire and their ability to learn tech, by building applications and educational experiences that make learning technology fast and frustration-free.
        </div>
      </div>
    </div>
  );
};

export default Home3;
