// Home8.js

import React, { useEffect, useRef } from 'react';
import './Home8.css';
import clipsImage from './clips.png'; // Replace with your actual image path
import kidImage from './kid.png'; // Replace with your actual image path
import phoneImage from './phone.png'; // Replace with your actual image path

const Home8 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home8-container">
      <div className="home8-content" ref={contentRef}>
        <div className="home8-subtext-container">
          <div className="home8-subtext">
            <span className="highlight-c8">1/2</span>
          </div>
          <div className="home8-kid-and-clips">
            <img src={kidImage} alt="Kid" className="home8-kid-image" />
            <img src={clipsImage} alt="Clips" className="home8-clips-image" />
          </div>
        </div>
        <div className="home8-bottom-section">
          <img src={phoneImage} alt="Phone" className="home8-phone-image" />
          <div className="home8-text-container">
            <h2 className="home8-title">Using AI to Bring Dynamic Ed Tech Content to Learners Without Internet Access!</h2>
            <p>We design all features of our applications with a foundation in proven concepts from the field of educational psychology.</p>
            <p>One of the primary concepts used is the Zone of Proximal Development (ZPD).</p>
            <p>With the ZPD approach, our applications dynamically allocate content and real-time AI assistance to meet learners exactly where they are in their learning journey.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home8;
