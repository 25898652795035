// Home9.js

import React, { useEffect, useRef } from 'react';
import './Home9.css';
import ProductGrid from './ProductGrid';

const Home9 = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const currentRef = contentRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible9');
        }
      },
      { threshold: 0 } // Increase the threshold to avoid premature animation
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="home9-container">
      <div className="home9-content" ref={contentRef}>
        <h1 className="home9-text">Tools</h1>
        <div className="home9-subtext">
          {/* <span className="highlight9-c">B</span>ridget bridges the gap between a learner's desire and their ability to learn tech, by building applications and educational experiences that make learning technology fast and frustration-free. */}
        </div>
        <ProductGrid />
      </div>
    </div>
  );
};

export default Home9;
